// eslint-disable-next-line no-unused-vars
import { Component } from 'react';
import PropTypes from 'prop-types';

export default class OfferUsedModel extends Component {
  constructor(props) {
    super(props);

    this.formatPrice = this.formatPrice.bind(this);
  }

  formatPrice(priceValue) {
    if (!priceValue) return null;

    return priceValue.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  render() {
    const {
      badge,
      name,
      slug,
      item_image,
      model,
      subtitle,
      exchange,
      fuel,
      km,
      fuel_text,
      old_price,
      price,
      cardLink,
    } = this.props;

    return (
      <div>
        <div className="card__header">
          <div className="card__badges">
            <div className="card__badge-item badge badge--pill badge--card-used-model">
              {badge}
            </div>
          </div>
          <a aria-label={name} href={`${cardLink}/${slug}`}>
            <div className="card__image card__image--used-models">
              <img
                loading="lazy"
                className="card__image-value card__image-value--used-models"
                src={item_image.middle_image}
                alt={name}
                width="280"
                height="215"
              />
            </div>
          </a>
        </div>
        <div
          className="card__content"
          data-match-height="offers-used-models__content"
        >
          <div className="card__titles">
            <div className="card__title">{model}</div>
            <div className="card__subtitle">{subtitle}</div>
          </div>

          {!!exchange || !!fuel ? (
            <ul className="card__seal-list list">
              {exchange ? (
                <li className="card__seal-list-item">
                  <b className="mr-1">Câmbio: </b> {exchange}
                </li>
              ) : null}

              {typeof km === 'number' ? (
                <li className="card__seal-list-item">
                  <b className="mr-1">Quilometragem: </b> {km} KM
                </li>
              ) : null}

              {fuel_text ? (
                <li className="card__seal-list-item">
                  <b className="mr-1">Combustível: </b> {fuel_text}
                </li>
              ) : null}
            </ul>
          ) : null}
        </div>

        <div
          className="card__footer"
          data-match-height="offers-used-models__footer"
        >
          <div className="card__triggers">
            <div className="card__trigger">{this.formatPrice(old_price)}</div>
            <div className="card__trigger-title">Por apenas</div>
            <div className="card__trigger-value">{this.formatPrice(price)}</div>
          </div>
          <a
            href={`${cardLink}/${slug}`}
            className="btn button button--block button--large button--dark card__cta card__cta-used-model"
          >
            {' '}
            Estou interessado{' '}
          </a>
        </div>
      </div>
    );
  }
}

OfferUsedModel.propTypes = {
  badge: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  item_image: PropTypes.shape({
    middle_image: PropTypes.string,
  }),
  model: PropTypes.string,
  subtitle: PropTypes.string,
  exchange: PropTypes.string,
  fuel: PropTypes.string,
  km: PropTypes.number,
  fuel_text: PropTypes.string,
  old_price: PropTypes.number,
  price: PropTypes.number,
  cardLink: PropTypes.string,
};
