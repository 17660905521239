import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/OfferUsedModel';
import PlaceholderCard from '../../Cards/Placeholder';

export default class OffersUsedModelsLazy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicles: [],
      isFetching: true,
    };
    this.carouselInstance = null;

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchOffers = this.fetchOffers.bind(this);
    this.cardProps = this.cardProps.bind(this);
  }

  componentDidMount() {
    this.fetchOffers();
    this.carouselInstance = this.initCarousel();
  }

  componentDidUpdate() {
    this.carouselInstance = this.initCarousel();
    window.matchHeight.match('.offers-used-models-lazy__carousel-wrapper');
  }

  fetchOffers() {
    const params = [{ new_vehicle: true }, { per_page: 8 }, { page: 1 }];
    window.autoServiceApiV2.getUsedModels(params).then(data =>
      this.setState({
        vehicles: data.entries,
        isFetching: false,
      }),
    );
  }

  initCarousel() {
    if (this.carouselInstance) this.carouselInstance.destroy();

    const carousel = window.makeCarousel(this.carouselRef, {
      gap: 15,
      perView: 4,
      rewind: false,
      bound: true,
      breakpoints: {
        560: {
          perView: 1.15,
        },
        992: {
          perView: 2.15,
        },
        1270: {
          perView: 3.15,
        },
      },
    });

    return carousel;
  }

  cardProps(vehicle) {
    return {
      badge: vehicle.badge,
      name: vehicle.name,
      slug: vehicle.slug,
      item_image: vehicle.item_image,
      model: vehicle.model,
      subtitle: vehicle.subtitle,
      exchange: vehicle.exchange,
      fuel: vehicle.fuel,
      km: vehicle.km,
      fuel_text: vehicle.fuel_text,
      item_offer: vehicle.item_offer,
      old_price: vehicle.old_price,
      price: vehicle.price,
      cardLink: this.props.cardLink,
    };
  }

  render() {
    const { vehicles, isFetching } = this.state;
    const { title, subtitle, titleIcon, cardLink } = this.props;

    if (!isFetching && (vehicles.length === 0 || !vehicles)) return null;

    const placeholders = Array(4).fill(null);
    const listItemClasses =
      'used-models-card--offers card card--with-badge glide__slide glide__slide--active';

    return (
      <div>
        <div className="container">
          <header className="carousel__header">
            {titleIcon !== '' ? (
              <i
                className={`icon icon--section icon-offer carousel__icon-header icon-${titleIcon}`}
              />
            ) : null}
            <div className="carousel__header-texts">
              <h2 className="carousel__title">
                {title}{' '}
                <span className="carousel__title-highlight">{subtitle}</span>
              </h2>
            </div>
          </header>
        </div>

        <div
          className="offers-used-models__carousel glide"
          ref={node => {
            this.carouselRef = node;
          }}
        >
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {isFetching
                ? placeholders.map((_, index) => (
                    <li
                      className={listItemClasses}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`used-models-placeholder-${index}`}
                    >
                      <PlaceholderCard
                        customClasses="new-vehicles-lazy__placeholder-card"
                        showBody={false}
                      />
                    </li>
                  ))
                : vehicles.map(vehicle => (
                    <li className={listItemClasses} key={vehicle.id}>
                      <Card {...this.cardProps(vehicle)} />
                    </li>
                  ))}
            </ul>
          </div>

          <div className="glide__arrows" data-glide-el="controls">
            <div className="product-carousel__arrow-prev" data-glide-dir="<">
              <i className="icon icon-arrow-r" />
            </div>
            <div className="product-carousel__arrow-next" data-glide-dir=">">
              <i className="icon icon-arrow-r" />
            </div>
          </div>
        </div>

        <div className="container product-carousel__ctas text-center">
          <a
            title="Todas as Ofertas de Seminovos"
            href={cardLink}
            className="btn button button--large button--section button--carousel-used-models-section"
          >
            Quero ver outros modelos{' '}
            <i className="icon icon-arrow-pointing-to-right" />
          </a>
        </div>
      </div>
    );
  }
}

OffersUsedModelsLazy.propTypes = {
  cardLink: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleIcon: PropTypes.string,
};
